import "../App.css";
import io from "socket.io-client";
import logo from "../assets/logo.png";
import moment from "moment-timezone";
import bgimg from "../assets/bgimg.png";
import Blocked from "../components/Blocked";
import indiaflag from "../assets/india.png";
import londonflag from "../assets/london.png";
import newyorkflag from "../assets/newyork.png";
import { useEffect, useMemo, useState } from "react";
import MarketClose from "../components/MarketClose";
import bullionStats from "../assets/bullioStats.png";
import { Box, Modal, Typography } from "@mui/material";
import TradingViewChart from "../components/TradingViewChart";
import EconomicNewsModal from "../components/EconomicNewsModal";
import SubscriptionExpired from "../components/SubscriptionExpired";
import { commodityCalculation } from "../components/constants";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import {
  allCommodities,
  allNewses,
  findBanners,
  getAdminProfile,
  getConversionValue,
  getEconomicNews,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");
let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${bgimg})`,
    width: "100vw",
    height: "100dvh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    color: "#FFFFFF",
  },
  mainContainer: {
    width: "100%",
    height: "91vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.4fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "1.5rem 2rem 2rem 2rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logo: {
    height: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
  },
  logoImg: {
    width: "65%",
    objectFit: "cover",
    overflow: "hidden",
  },
  timesMainBox: {
    height: "10%",
    background: " linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%)",
    padding: "1px 1px 0px 1px",
    borderRadius: "8px",
  },
  flagimg: {
    width: "4vw",
    objectFit: "cover",
    height: "4.5vh",
  },
  commoditieTable: {
    height: "49%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    borderRadius: "5px",
  },
  table: {
    gap: "10px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    height: "20%",
    background: " linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%)",
    padding: "1px",
    borderRadius: "8px 8px 0px 0px",
  },
  tabeHeaderInner: {
    backgroundColor: "#000000",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px 8px 0px 0px",
    height: "100%",
    boxSizing: "border-box",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "80%",
    flexDirection: "column",
    gap: "10px",
    justifyContent: "space-between",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2vw",
  },
  tableRow: {
    flexGrow: 1,
    minHeight: "21%",
    maxHeight: "21%",
    flexBasis: 0,
    background: " linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%)",
    padding: "1px",
  },
  tableRowInner: {
    background: "#424344",
    boxSizing: "border-box",
    display: "flex",
    height: "100%",
  },
  currencyMainBox: {
    height: "12%",
    display: "flex",
    justifyContent: "space-between",
  },
  currencySubBox: {
    background: " linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%)",
    padding: "1px",
    width: "40%",
    borderRadius: "8px",
    height: "100%",
  },
  currencySubInner: {
    height: "100%",
    boxSizing: "border-box",
    borderRadius: "8px",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    backgroundColor: "#424344",
    alignItems: "center",
    gap: "10px",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
  },
  header: {
    height: "30%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  spotRate: {
    height: "100%",
    width: "60%",
    background: " linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%)",
    padding: "1px",
    borderRadius: "8px",
  },
  spotRateInner: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: ".6rem",
    backgroundColor: "#363637",
    height: "100%",
    borderRadius: "8px",
  },
  spotRateBoxGoldRowCol: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateGold: {
    border: "1px solid #FFFFFF",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "11.5vw",
    height: "8vh",
    display: "flex",
    alignItems: "center",
    transition: "background-color .5s ease",
    justifyContent: "center",
  },
  bullionStatsImg: {
    width: "13vw",
    height: "auto",
  },

  TradingViewChart: {
    height: "56%",
    width: "100%",
  },

  updates: {
    padding: "0rem 2rem 1.5rem 2rem",
    color: "#fff",
    display: "flex",
    height: "6%",
    color: "#FFFFFF",
  },
  updatesHeader: {
    minWidth: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: " linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%)",
    boxSizing: "border-box",
    padding: "1px 0px 0px 1px",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: " linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%)",
    flex: 1,
    boxSizing: "border-box",
    padding: "1px 1px 1px 0px",
    clipPath: "polygon(0 0, 95% 0%, 100% 100%, 0% 100%)",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [currency, setCurrency] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [goldNews, setGoldNews] = useState({});

  ////////////Block//////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };
  ///////////////////// SubscriptionExpierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();
  ///////////////////// SubscriptionExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().tz(adminData?.time_zone);
      const dayOfWeek = now.day();
      const hour = now.hour();
      console.log(dayOfWeek);
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      // handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  const handleFindCurrency = async () => {
    const res = await getConversionValue("USD");
    if (res) {
      setCurrency(res);
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
    handleFindCurrency();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };
  const formatday = () => {
    return moment(new Date()).format("dddd");
  };
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className={(openSubscriptionExpierd || openBlocked) && classes.blur}>
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.leftPart}>
            <Box className={classes.logo}>
              <img src={logo} alt="logo" className={classes.logoImg} />
            </Box>
            <Box className={classes.timesMainBox}>
              <Box
                sx={{
                  backgroundColor: "#424344",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gridTemplateRows: "1fr",
                  p: "15px 20px 0px 20px",
                  borderRadius: "8px",
                  boxSizing: "border-box",
                  height: "100%",
                }}
              >
                <Box sx={{ display: "flex", gap: 1 }}>
                  <img
                    src={londonflag}
                    alt="londonflag"
                    className={classes.flagimg}
                  />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "1vw",
                        fontWeight: 400,
                        mt: { lg: -0.5 },
                      }}
                    >
                      LONDON
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.5vw", fontWeight: 300, mt: -1 }}
                    >
                      {moment(Date.now()).tz("Europe/London").format("hh:mm A")}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <img
                    src={indiaflag}
                    alt="indiaflag"
                    className={classes.flagimg}
                  />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "1.1vw",
                        fontWeight: 400,
                        mt: { lg: -0.5 },
                      }}
                    >
                      INDIA
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.5vw",
                        mt: -1,
                        fontWeight: 300,
                      }}
                    >
                      {moment(Date.now()).tz("Asia/Kolkata").format("hh:mm A")}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <img
                    src={newyorkflag}
                    alt="NEW YORK"
                    className={classes.flagimg}
                  />
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "1.1vw",
                        fontWeight: 400,
                        mt: { lg: -0.5 },
                      }}
                    >
                      NEW YORK
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1.5vw", fontWeight: 300, mt: -1 }}
                    >
                      {moment(Date.now())
                        .tz("America/New_York")
                        .format("hh:mm A")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Box className={classes.tabeHeaderInner}>
                    <Typography
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "1.7vw",
                        fontWeight: "bold",
                      }}
                    >
                      COMMODITY
                    </Typography>
                    <Typography
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "1.7vw",
                        fontWeight: "bold",
                      }}
                    >
                      WEIGHT
                    </Typography>{" "}
                    <Typography
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "1.7vw",
                        fontWeight: "bold",
                      }}
                    >
                      SELL
                      <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                        &nbsp;AED
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box key={idx} className={classes.tableRow}>
                        <Box className={classes.tableRowInner}>
                          <Typography
                            sx={{
                              fontSize: "1.3vw",
                              fontWeight: "bold",
                              justifyContent: "left",
                            }}
                            className={classes.tableRowColumn}
                          >
                            {words?.map((word, index) => {
                              if (index === 0) {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "1.7vw",
                                      fontWeight: "bold",
                                      paddingLeft: "30px",
                                    }}
                                  >
                                    {word.toUpperCase()}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "1vw",
                                      marginTop: "0.2rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &nbsp;{word.toUpperCase()}
                                  </span>
                                );
                              }
                            })}
                            &nbsp;
                            {commodity.unitLabel === "TTB"
                              ? ""
                              : commodity.displayPurity}
                          </Typography>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "1.7vw", fontWeight: "bold" }}
                              sx={{
                                paddingLeft: { lg: "4.5vw", md: "3.5vw" },
                              }}
                            >
                              {commodity.unit} {commodity.unitLabel}
                            </Typography>
                          </Box>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "1.7vw", fontWeight: "bold" }}
                              sx={{
                                paddingLeft: { lg: "3.3vw", md: "3vw" },
                              }}
                            >
                              {!gold?.cur?.bid
                                ? "0.00"
                                : commodityCalculation(
                                    commodity.commodity_title === "Silver"
                                      ? silver?.cur?.ask
                                      : gold?.cur?.ask,
                                    commodity.commodity_title === "Silver"
                                      ? spread.silverAskSpread
                                      : spread.goldAskSpread,
                                    commodity.premium,
                                    3.674,
                                    commodity.purity,
                                    commodity.unit,
                                    commodity.unitLabel === "TTB"
                                      ? 116.64
                                      : commodity.unitLabel === "KG"
                                      ? 1000
                                      : commodity.unitLabel === "OZ"
                                      ? 31.1
                                      : commodity.unitLabel === "TOLA"
                                      ? 11.7
                                      : 1,
                                    commodity.charges
                                  )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box className={classes.currencyMainBox}>
              <Box className={classes.currencySubBox}>
                <Box className={classes.currencySubInner}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.5vw",
                      }}
                    >
                      &nbsp;&nbsp;THB /
                    </Typography>
                    <Typography sx={{ fontSize: "1.5vw", mt: -1 }}>
                      &nbsp;&nbsp;USD &nbsp;
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "1.5vw" }}>
                    {Number(currency?.conversion_rates?.THB).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.currencySubBox}>
                <Box className={classes.currencySubInner}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.5vw",
                      }}
                    >
                      &nbsp;&nbsp;INR /
                    </Typography>
                    <Typography sx={{ fontSize: "1.5vw", mt: -1 }}>
                      &nbsp;&nbsp;USD
                    </Typography>
                  </Box>
                  <Typography sx={{ fontSize: "1.5vw" }}>
                    {Number(currency?.conversion_rates?.INR).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.header}>
              <Box className={classes.spotRate}>
                <Box className={classes.spotRateInner}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "end",
                      height: "25%",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.4VW",
                        fontWeight: 500,
                      }}
                    >
                      GOLD SPOT RATE
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "15%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "11.5vw",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: ".9vw",
                            fontWeight: "normal",
                            backgroundColor: "#141618",
                            padding: "2px 5px 2px 5px",
                            borderRadius: "3px",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          $
                        </Typography>
                        &nbsp;
                        <Typography
                          sx={{
                            fontSize: "1.4vw",
                            fontWeight: "bold",
                            width: "11.5vw",
                          }}
                        >
                          {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                          <span
                            style={{ fontSize: "1.2vw", fontWeight: "normal" }}
                          >
                            &nbsp;oz
                          </span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                        flexBasis: 0,
                        flexGrow: 1,
                        flexShrink: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: "11.5vw",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: ".9vw",
                            fontWeight: "normal",
                            backgroundColor: "#141618",
                            padding: "2px 5px 2px 5px",
                            borderRadius: "3px",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          $
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1.4vw",
                            fontWeight: "bold",
                          }}
                        >
                          &nbsp;
                          {displayBidOrBuy?.askOrSell?.toUpperCase()}
                        </Typography>
                        <Typography
                          style={{ fontSize: "1.2vw", fontWeight: "normal" }}
                        >
                          &nbsp;oz
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      height: "55%",
                      display: "flex",
                      alignItems: "start",
                    }}
                  >
                    <Box className={classes.spotRateBoxGoldRowCol}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(gold?.cur?.bid).toFixed(2) <
                            Number(gold?.pre?.bid).toFixed(2)
                              ? "#DE5B56"
                              : Number(gold?.cur?.bid).toFixed(2) >
                                Number(gold?.pre?.bid).toFixed(2)
                              ? "#3DA1EA"
                              : "",
                        }}
                        className={classes.spotRateGold}
                      >
                        <Typography
                          sx={{ fontSize: "2.5vw", fontWeight: "bold" }}
                        >
                          {(!gold?.cur?.bid
                            ? 0
                            : Number(spread?.goldBidSpread) +
                              Number(gold?.cur?.bid)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          width: "11.5vw",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: ".8vw",
                            px: 1,
                            backgroundColor: "#DE5B56",
                            borderRadius: "3px",
                          }}
                        >
                          Low
                        </Typography>

                        <Typography style={{ fontSize: "1.1vw" }}>
                          {(!gold?.cur?.bid
                            ? 0
                            : Number(spread.goldLowSpread) +
                              Number(gold?.cur?.lowPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className={classes.spotRateBoxGoldRowCol}>
                      <Box
                        sx={{
                          backgroundColor:
                            Number(gold?.cur?.ask).toFixed(2) <
                            Number(gold?.pre?.ask).toFixed(2)
                              ? "#DE5B56"
                              : Number(gold?.cur?.ask).toFixed(2) >
                                Number(gold?.pre?.ask).toFixed(2)
                              ? "#3DA1EA"
                              : "",
                        }}
                        className={classes.spotRateGold}
                      >
                        <Typography
                          sx={{ fontSize: "2.5vw", fontWeight: "bold" }}
                        >
                          {(!gold?.cur?.bid
                            ? 0
                            : Number(spread?.goldAskSpread) +
                              Number(gold?.cur?.ask)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          width: "11.5vw",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: ".8vw",
                            px: 1,
                            backgroundColor: "#3DA1EA",
                            borderRadius: "3px",
                          }}
                        >
                          High
                        </Typography>
                        <Typography style={{ fontSize: "1.1vw" }}>
                          {(!gold?.cur?.bid
                            ? 0
                            : Number(spread?.goldHighSpread) +
                              Number(gold?.cur?.highPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  color: "#fff",
                  alignItems: "end",
                  flexDirection: "column",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "2.5vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatTime()
                      .toUpperCase()
                      .split("")
                      .map((item) => (
                        <span>{item}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.9vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatday()
                      .toUpperCase()
                      .split("")
                      .map((item) => (
                        <span>{item}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.8vw",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    {formatDate()
                      .toUpperCase()
                      .split("")
                      .map((item) => (
                        <span>{item}</span>
                      ))}
                  </Typography>{" "}
                </Box>
              </Box>
            </Box>

            <Box className={classes.TradingViewChart}>
              <TradingViewChart symbol={"XAUUSD"} />
            </Box>
            <Box
              sx={{
                height: "8%",
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <a href="https://www.bullionstats.com/">
                <img
                  className={classes.bullionStatsImg}
                  src={bullionStats}
                  alt="bullionStats"
                />
              </a>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box className={classes.updatesHeader}>
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "#424344",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                boxSizing: "border-box",
                justifyContent: "center",
                letterSpacing: "3px",
              }}
            >
              DIVINE ERA
            </Typography>
          </Box>
          <Box className={classes.updatesContent} sx={{ minHeight: "100%" }}>
            <Box
              sx={{
                backgroundColor: "#000000",
                width: "calc(100vw - 200px - 4rem)",
                maxWidth: "calc(100vw - 200px - 4rem)", // Add this line
                height: "100%",
                display: "flex",
                alignItems: "center",
                boxSizing: "border-box",
                justifyContent: "center",
                overflow: "hidden",
              }}
            >
              <marquee behavior="" direction="">
                <Box sx={{ display: "flex" }}>
                  {Object.keys(goldNews).length > 0 ? (
                    <Box
                      style={{
                        fontSize: "1.5vw",
                        fontFamily: "poppins",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        Gold Price News
                      </span>
                      &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews.title}</span>
                    </Box>
                  ) : (
                    news?.map((item, index) => {
                      return (
                        <Box
                          key={item._id}
                          style={{
                            fontSize: "1.5vw",
                            fontFamily: "poppins",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {item.newsTitle}
                          </span>
                          &nbsp;&nbsp;:&nbsp;&nbsp;
                          <span>{item.newsDetails}</span>
                          <span
                            style={{
                              paddingRight: "50px",
                              paddingLeft: "50px",
                            }}
                          >
                            {index === news.length - 1 ? "" : "|"}
                          </span>
                        </Box>
                      );
                    })
                  )}
                </Box>
              </marquee>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </div>
  );
};

export default Home;
